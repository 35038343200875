import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 500.000000 500.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">
		<path d="M0 2500 l0 -2500 2500 0 2500 0 0 2500 0 2500 -2500 0 -2500 0 0
-2500z m1962 429 c16 -9 16 -12 1 -44 -13 -27 -23 -35 -44 -35 -39 0 -51 -21
-47 -82 l3 -53 38 -3 c37 -3 38 -4 35 -40 -3 -35 -5 -37 -40 -40 l-38 -3 0
-120 0 -119 -52 0 -53 0 2 105 c3 118 -1 135 -28 135 -15 0 -19 7 -19 40 0 33
4 40 19 40 22 0 31 26 31 90 1 113 98 178 192 129z m-708 -30 c31 -25 33 -57
6 -87 -28 -30 -64 -28 -89 4 -28 35 -26 50 5 79 33 30 44 31 78 4z m351 -200
c49 -23 95 -89 95 -136 0 -80 -53 -144 -138 -169 -61 -18 -121 -9 -178 27 -34
21 -74 93 -74 133 0 61 56 137 113 155 75 23 116 21 182 -10z m617 -2 c38 -21
67 -70 71 -122 l2 -30 -117 -3 c-109 -2 -118 -4 -118 -22 0 -43 91 -67 137
-35 19 13 25 13 52 -3 17 -9 31 -20 31 -24 0 -15 -64 -58 -102 -69 -80 -21
-161 13 -203 85 -90 153 85 312 247 223z m313 23 c39 -1 50 -15 36 -49 -13
-29 -15 -30 -57 -24 -90 12 -95 -21 -8 -57 67 -27 86 -54 82 -111 -2 -36 -8
-47 -36 -66 -62 -41 -158 -40 -216 2 -14 10 -15 17 -7 27 7 7 17 21 25 32 12
17 14 18 45 2 41 -22 75 -17 79 9 2 15 -9 26 -48 44 -90 43 -111 100 -57 158
21 24 88 44 122 37 11 -2 29 -4 40 -4z m348 -12 c12 -6 16 -15 12 -26 -4 -9
-10 -25 -12 -34 -4 -15 -12 -17 -45 -12 -50 9 -84 -9 -98 -51 -12 -37 -3 -71
29 -99 16 -14 30 -17 62 -13 38 5 41 3 54 -26 18 -42 6 -54 -60 -62 -101 -13
-205 75 -205 175 0 65 61 137 138 160 30 10 97 3 125 -12z m337 -11 c45 -27
54 -36 74 -74 64 -125 -72 -268 -222 -234 -94 20 -147 82 -145 168 0 59 16 89
68 129 64 48 156 53 225 11z m413 6 c15 -7 27 -18 27 -23 0 -16 15 -12 41 11
51 45 111 47 163 5 35 -27 47 -87 45 -213 l-2 -93 -53 0 -54 0 0 107 c0 118
-11 143 -59 143 -51 0 -56 -10 -59 -135 l-4 -115 -49 0 -49 0 0 103 c0 121
-11 147 -65 147 -48 0 -55 -20 -55 -145 l0 -106 -52 3 -53 3 0 160 0 160 53 3
c42 2 52 0 52 -12 0 -20 10 -20 35 -2 39 27 83 27 138 -1z m-2363 -190 c0
-168 -3 -203 -18 -233 -22 -44 -80 -75 -125 -66 -55 11 -61 18 -44 59 12 27
22 36 44 39 l28 3 3 198 2 197 55 0 55 0 0 -197z m2790 -38 l0 -85 -55 0 -55
0 0 85 0 85 55 0 55 0 0 -85z m-2583 -172 l32 -38 1 38 c0 31 3 38 18 35 11
-2 17 -12 16 -31 -1 -15 -2 -45 -3 -67 -1 -51 -17 -51 -58 2 l-33 40 0 -41 c0
-30 -4 -41 -15 -41 -12 0 -15 14 -15 70 0 82 10 88 57 33z m231 25 c-3 -7 -21
-14 -41 -16 -45 -4 -49 -20 -5 -24 42 -4 50 -28 9 -28 -21 0 -31 -5 -31 -15 0
-10 11 -15 35 -15 24 0 35 -5 35 -15 0 -12 -13 -15 -55 -15 l-55 0 0 70 0 70
56 0 c40 0 55 -4 52 -12z m160 0 c-3 -7 -14 -14 -26 -16 -20 -3 -22 -10 -22
-58 0 -41 -4 -54 -15 -54 -12 0 -15 13 -15 55 0 48 -2 55 -20 55 -11 0 -20 7
-20 15 0 12 13 15 61 15 44 0 60 -3 57 -12z m1552 -93 l0 -55 -775 0 -775 0 0
55 0 55 775 0 775 0 0 -55z"/>
<path d="M1473 2630 c-44 -18 -58 -90 -24 -131 24 -30 83 -32 112 -2 27 27 28
88 3 114 -24 22 -63 31 -91 19z"/>
<path d="M2082 2644 c-42 -29 -28 -44 43 -44 70 0 82 11 47 43 -23 21 -61 22
-90 1z"/>
<path d="M3083 2630 c-28 -12 -43 -40 -43 -83 0 -62 74 -96 123 -57 22 17 27
30 27 64 0 62 -52 99 -107 76z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
